import { makeStyles, Typography } from '@material-ui/core';
import nochoice from 'client_admin/src/images/settings/edit-menu.svg';
import { endOfToday, format } from 'date-fns';
import { DTOCategory, DTOProduct } from 'models';
import React, { FC, useContext } from 'react';
import { UIContext } from '../../../../modules/general/ui';
import { adminMenuActions, AdminMenuContext, adminMenuOperations } from '../../../../modules/settings';
import { FunctionsMenuButton } from '../../../general/parts/functionsMenuButton';
import { ClassName } from '../../../general/props/classname';
import { AdminButton } from '../../../general/units/adminButton';
import { AdminBorder } from '../../parts/adminBorder';
import { SoldoutProductListHeader } from './soldoutProductListHeader';
import { SoldoutProductListItem } from './soldoutProductListItem';

type Props = ClassName;

const useStyles = makeStyles((theme) => ({
  wrapper: {
    height: '100%',
    width: 'calc((100vw - 18.5rem) * 0.55)',
    borderRight: '1px solid #cbcbcb',
    overflow: 'hidden',
    position: 'relative',
    flexGrow: 1,
    '@media (max-width: 1024px)': {
      width: 'calc((100vw - 15.5rem) * 0.55)',
    },
  },
  unselectMessageWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  functionsMenuButtonWrapper: {
    margin: '2rem 2rem 0 auto',
  },
  imageContent: {
    width: '40%',
    minWidth: '25rem',
  },
  image: {
    width: '100%',
    opacity: '0.3',
  },
  unselectMessage: {
    color: theme.palette.text.primary,
    '@media (max-width: 1024px)': {
      fontSize: '0.8rem',
    },
    textAlign: 'center',
    marginTop: '-3rem',
  },
  confirmButton: {
    display: 'flex',
    marginLeft: '15%',
    width: '180px',
    fontSize: '1vh',
    marginBottom: '20%',
  },
  bottom: {
    display: 'flex',
    width: '80%',
    margin: '0 auto',
    flexDirection: 'row',
    marginTop: '3%',
    justifyContent: 'flex-end',
  },
  border: {
    width: '85%',
    margin: '0 auto',
  },
  lists: {
    height: '65%',
    overflowY: 'scroll',
    '@media (max-width: 1024px)': {
      height: '55%',
    },
  },
}));

export const SoldoutProductList: FC<Props> = ({ className }) => {
  const classes = useStyles();

  const adminMenuStore = useContext(AdminMenuContext);
  const uiStore = useContext(UIContext);

  const selectCategory: DTOCategory | undefined = adminMenuStore.state.menus
    .flatMap((menu) => menu.categories.map((category: DTOCategory) => category))
    .find((category) => category.id === adminMenuStore.state.selectingCategoryId);

  const endOfTodayString = format(endOfToday(), 'yyyy/MM/dd HH:mm');
  const onClick = (product: DTOProduct) => {
    adminMenuStore.dispatch(
      adminMenuActions.updateChangedSoldoutProductsSucceed({
        id: product.id,
        soldoutEndDate: product.soldoutEndDate ? undefined : endOfTodayString,
      })
    );
  };

  // 確定ボタン押下時 ProductのsoldoutEndDateのみ更新
  const updateSoldoutEndDate = () => {
    adminMenuOperations.updateProductSoldoutEndDate(
      uiStore.dispatch,
      adminMenuStore.dispatch,
      adminMenuStore.state.changedSoldoutProducts
    );
  };

  return adminMenuStore.state.selectingCategoryId ? (
    <div className={`${classes.wrapper} ${className}`}>
      <SoldoutProductListHeader />
      <div className={classes.lists}>
        {selectCategory?.products.map((product) => {
          return (
            <SoldoutProductListItem
              key={`soldout-product-list-item-${product.id}`}
              product={product}
              onClick={onClick}
            />
          );
        })}
      </div>
      <AdminBorder className={classes.border} />
      <div className={classes.bottom}>
        <AdminButton className={classes.confirmButton} text="確定" color="positive" onClick={updateSoldoutEndDate} />
      </div>
    </div>
  ) : (
    <div className={`${classes.unselectMessageWrapper} ${className}`}>
      <FunctionsMenuButton className={classes.functionsMenuButtonWrapper} />
      <div className={classes.imageContent}>
        <img src={nochoice} alt="商品カテゴリを選んでください。" className={classes.image} />
        <Typography variant="body1" className={classes.unselectMessage}>
          商品カテゴリーを、 左メニューより選択してください。
        </Typography>
      </div>
    </div>
  );
};

import { DTOTax } from 'models';
import * as types from './types';

/**
 * 消費税率取得成功時アクション
 */
const getSucceed = (tax: DTOTax) => ({
  type: types.GET_SUCCEED,
  payload: {
    tax,
  },
});

export const actions = { getSucceed };
export type TypeOfActions = ReturnType<typeof getSucceed>;

import { DTOShop } from 'models';
import { Reducer } from 'react';
import { BASIC_INFORMATION } from '../../../constants/takeout/settings';
import { TypeOfActions } from './actions';
import * as types from './types';

export interface State {
  shop: DTOShop;
  selectingSetting: number;
}

export const initialState: State = {
  shop: {
    id: 1,
    name: '',
    postalCode: 0,
    prefecture: '',
    municipality: '',
    address: '',
    buildingName: '',
    phoneNumber: '',
    takeoutOrders: [],
    takeoutSetting: { shopId: 1, preparationTime: 0 },
    takeoutReceptionTimezones: [],
    takeoutHolidays: [],
  },
  selectingSetting: BASIC_INFORMATION.PREPARATION_TIME.value,
};

export const reducer: Reducer<State, TypeOfActions> = (state, action) => {
  switch (action.type) {
    case types.GET_SUCCEED:
      return {
        ...state,
        shop: action.payload.shop,
      };
    case types.UPDATE_PREPARATION_TIME_SUCCEED:
      return {
        ...state,
        shop: {
          ...state.shop,
          takeoutSetting: { ...state.shop.takeoutSetting, preparationTime: action.payload.preparationTime },
        },
      };
    case types.UPDATE_RECEPTION_TIMEZONES_SUCCEED:
      return {
        ...state,
        shop: {
          ...state.shop,
          takeoutReceptionTimezones: action.payload.receptionTimezones,
        },
      };
    case types.SELECT_SETTING:
      return {
        ...state,
        selectingSetting: action.payload.selectingSetting,
      };
    default:
      return state;
  }
};

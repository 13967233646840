import { makeStyles, Typography } from '@material-ui/core';
import { DTOCategory } from 'models';
import React, { FC } from 'react';

type Props = {
  category: DTOCategory;
  onClickFactory: (categoryId: number) => () => void;
  selected: boolean;
};

const useStyles = makeStyles((theme) => ({
  title: {
    display: 'flex',
    padding: '0.4rem',
    color: theme.palette.text.primary,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
    borderBottom: '1px solid #fff',
    '&:hover': {
      color: '#6b6b6b',
      backgroundColor: theme.sideMenu?.hover.background,
    },
  },
  selectedTitle: {
    backgroundColor: theme.sideMenu?.hover.background,
  },
}));

export const SoldoutCategory: FC<Props> = ({ category, onClickFactory, selected }) => {
  const classes = useStyles();
  return (
    <div
      role="button"
      className={`${classes.title} ${selected && classes.selectedTitle}`}
      tabIndex={0}
      onClick={onClickFactory(category.id)}
      onKeyDown={onClickFactory(category.id)}
    >
      <Typography>{category.name}</Typography>
    </div>
  );
};

import { fetchman } from '../../../utils/fetchman';
import { uiActions } from '../ui';
import { TypeOfActions as TypeOfUIActions } from '../ui/actions';
import { actions, TypeOfActions } from './actions';

/**
 * GET /api/tax
 */
export const getTax = async (uiDispatch: React.Dispatch<TypeOfUIActions>, dispatch: React.Dispatch<TypeOfActions>) => {
  try {
    uiDispatch(uiActions.fetchProgress());

    const data = await fetchman(`/api/tax`, { method: 'GET' });

    dispatch(actions.getSucceed(data.result));
  } catch (error) {
    uiDispatch(uiActions.fetchFailed('消費税率の取得に失敗しました'));
  }
};

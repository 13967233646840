import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import { green } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core/styles';
import React, { FC } from 'react';
import { ClassName } from '../../../general/props/classname';

type Props = {
  value?: number;
  checked?: boolean;
  onClick?: () => void;
} & ClassName;

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
  // eslint-disable-next-line react/jsx-props-no-spreading
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

export const SoldoutProductCheckbox: FC<Props> = ({ checked, onClick, value }) => {
  return <GreenCheckbox checked={checked} value={value} onClick={onClick} />;
};

import { DTOShop, DTOTakeoutReceptionTimezone } from 'models';
import * as types from './types';

/**
 * 店舗情報取得成功時アクション
 */
const getSucceed = (shop: DTOShop) => ({
  type: types.GET_SUCCEED,
  payload: {
    shop,
  },
});

/**
 * 商品準備時間更新成功時アクション
 */
const updatePreparationTimeSucceed = (preparationTime: number) => ({
  type: types.UPDATE_PREPARATION_TIME_SUCCEED,
  payload: {
    preparationTime,
  },
});

/**
 * 受取時間帯更新成功時アクション
 */
const updateReceptionTimezonesSucceed = (receptionTimezones: DTOTakeoutReceptionTimezone[]) => ({
  type: types.UPDATE_RECEPTION_TIMEZONES_SUCCEED,
  payload: {
    receptionTimezones,
  },
});

const selectSetting = (selectingSetting: number) => ({
  type: types.SELECT_SETTING,
  payload: {
    selectingSetting,
  },
});

export const actions = {
  getSucceed,
  updatePreparationTimeSucceed,
  updateReceptionTimezonesSucceed,
  selectSetting,
};
export type TypeOfActions = ReturnType<
  | typeof getSucceed
  | typeof updatePreparationTimeSucceed
  | typeof updateReceptionTimezonesSucceed
  | typeof selectSetting
>;

import { Button, makeStyles, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { ClassName } from '../props/classname';

type Props = ClassName & {
  color: 'positive' | 'negative' | 'default';
  background?: string;
  text: string;
  textColor?: string;
  disabled?: boolean;
  autoFocus?: boolean;
  onClick?: () => void;
};

const useStyles = makeStyles((theme) => ({
  button: {
    borderRadius: '1.5rem',
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
  },
  positive: {
    background: '#8acc9c',
    '&:hover': {
      background: '#75c38a',
    },
  },
  negative: {
    background: '#f6bcbc',
    '&:hover': {
      background: '#fa9f9f',
    },
  },
  default: {
    background: '#b7b7b7',
    '&:hover': {
      background: '#acacac',
    },
  },
  text: {
    color: '#ffffff',
    fontSize: '1rem',
  },
}));

export const AdminButton: FC<Props> = ({
  className,
  background,
  color,
  text,
  textColor,
  disabled,
  autoFocus,
  onClick,
}) => {
  const classes = useStyles();

  return (
    <Button
      className={`${classes.button} ${classes[color]} ${className}`}
      style={{ background }}
      variant="contained"
      disabled={disabled}
      onClick={onClick}
      autoFocus={autoFocus}
    >
      <Typography className={classes.text} variant="h6" style={{ color: `${textColor}` }}>
        {text}
      </Typography>
    </Button>
  );
};

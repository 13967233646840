import { createMuiTheme, responsiveFontSizes } from '@material-ui/core';

declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    iconSideMenu?: {
      title: {
        background: React.CSSProperties['color'];
      };
      background: React.CSSProperties['color'];
      width: React.CSSProperties['width'];
      hover: {
        background: React.CSSProperties['color'];
      };
    };
    sideMenu?: {
      title: {
        background: React.CSSProperties['color'];
      };
      background: React.CSSProperties['color'];
      width: React.CSSProperties['width'];
      hover: {
        background: React.CSSProperties['color'];
      };
    };
  }
  interface ThemeOptions {
    iconSideMenu?: {
      background: React.CSSProperties['color'];
      width: React.CSSProperties['width'];
      hover: {
        background: React.CSSProperties['color'];
      };
    };
    sideMenu?: {
      title: {
        background: React.CSSProperties['color'];
      };
      background: React.CSSProperties['color'];
      width: React.CSSProperties['width'];
      hover: {
        background: React.CSSProperties['color'];
      };
    };
  }
}

const theme = createMuiTheme({
  iconSideMenu: {
    background: '#8acc9c',
    width: '2.5rem',
    hover: {
      background: '#b9e0c4',
    },
  },
  sideMenu: {
    title: { background: '#9dd2ac' },
    background: '#ededed',
    width: '16rem',
    hover: {
      background: '#c5e6ce',
    },
  },
  palette: {
    primary: {
      main: '#addbba',
      light: '#cde8d4',
      dark: '#64b97a',
    },
    secondary: {
      main: '#ededed',
      light: '#ffffff',
      dark: '#b7b7b7',
    },
    text: {
      primary: '#5b5b5b',
      secondary: '#ffffff',
    },
  },
  typography: {
    fontSize: 14,
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      'Roboto',
      'Segoe UI',
      'Helvetica Neue',
      'HelveticaNeue',
      'YuGothic',
      'Yu Gothic Medium',
      'Yu Gothic',
      'Verdana',
      'Meiryo',
      'sans-serif',
    ].join(','),
  },
  overrides: {
    MuiDrawer: {
      paperAnchorBottom: {
        height: '95%',
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
      },
    },
    MuiTypography: {
      colorTextSecondary: { color: '#5b5b5b' },
    },
  },
});

export const takeoutTheme = responsiveFontSizes(theme);

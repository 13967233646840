import { makeStyles, ThemeProvider } from '@material-ui/core';
import React, { FC } from 'react';
import { SoldoutLayout } from '../../../components/settings/layouts/soldoutLayout';
import { takeoutTheme } from '../../../components/takeout/theme/takeoutTheme';
import { TaxContextProvider } from '../../../modules/general/tax';
import { UIContextProvider } from '../../../modules/general/ui';
import { AdminMenuContextProvider } from '../../../modules/settings';
import { ShopContextProvider } from '../../../modules/takeout/shop';

const useStyles = makeStyles(() => ({
  wrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
  },
}));

const SoldoutProductPage: FC = () => {
  const classes = useStyles();

  return (
    <UIContextProvider>
      <TaxContextProvider>
        <ShopContextProvider>
          <AdminMenuContextProvider>
            <ThemeProvider theme={takeoutTheme}>
              <div className={classes.wrapper}>
                <SoldoutLayout />
              </div>
            </ThemeProvider>
          </AdminMenuContextProvider>
        </ShopContextProvider>
      </TaxContextProvider>
    </UIContextProvider>
  );
};

export default SoldoutProductPage;

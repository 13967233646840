import { DTOTax } from 'models';
import { Reducer } from 'react';
import { TypeOfActions } from './actions';
import * as types from './types';

export interface State {
  tax: DTOTax | undefined;
}

export const initialState: State = {
  tax: undefined,
};

export const reducer: Reducer<State, TypeOfActions> = (state, action) => {
  switch (action.type) {
    case types.GET_SUCCEED:
      return {
        ...state,
        tax: action.payload.tax,
      };
    default:
      // eslint-disable-next-line
      return state;
  }
};

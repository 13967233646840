import { DTOCategory } from 'models';
import React, { FC, useContext, useEffect, useState } from 'react';
import { AdminMenuContext } from '../../../../modules/settings';
import { getCategoriesAsSideMenuProps } from '../../../../modules/settings/selectors';
import { SoldoutCategory } from './soldoutCategory';

type Props = {
  categories: DTOCategory[];
  onClickFactory: (categoryId: number) => () => void;
};

export const SoldoutCategoryList: FC<Props> = ({ categories, onClickFactory }) => {
  const adminMenuStore = useContext(AdminMenuContext);
  const [selectedCategory, setSelectedCategory] = useState(
    getCategoriesAsSideMenuProps(categories, adminMenuStore.state)
  );

  useEffect(() => {
    setSelectedCategory(getCategoriesAsSideMenuProps(categories, adminMenuStore.state));
  }, [categories, adminMenuStore.state.selectingCategoryId]);

  return (
    <div>
      {selectedCategory.map((category) => (
        <SoldoutCategory
          category={category}
          onClickFactory={onClickFactory}
          key={`category-${category.id}`}
          selected={category.selected}
        />
      ))}
    </div>
  );
};

export const BASIC_INFORMATION = {
  PREPARATION_TIME: { title: '商品準備時間', value: 1 },
  RECEPTION_TIMEZONES: { title: '商品受渡し時間帯', value: 2 },
  SHOP_INFORMATION: { title: '店舗情報', value: 3 },
};

export const PREPARATION_TIMES = [5, 10, 15, 30, 45, 60] as const;

export const DAYS_OF_THE_WEEK = [
  { value: 0, name: '日' },
  { value: 1, name: '月' },
  { value: 2, name: '火' },
  { value: 3, name: '水' },
  { value: 4, name: '木' },
  { value: 5, name: '金' },
  { value: 6, name: '土' },
] as const;

export const MESSAGE = {
  VALIDATION: {
    START_BLANK: '開始時刻を入力してください',
    END_BLANK: '終了時刻を入力してください',
    INVALID_END_TIME: '終了時刻を開始時刻より後に設定してください',
    INVALID_DINNER_START_TIME: '開始時刻をランチ終了時刻より後に設定してください',
  },
} as const;

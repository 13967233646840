import { Chip, makeStyles, Typography } from '@material-ui/core';
import { DTOProduct } from 'models';
import React, { FC } from 'react';

type Props = {
  product: DTOProduct;
};

const useStyles = makeStyles(() => ({
  productInfoText: {
    width: '100%',
    height: '100%',
    minWidth: '20rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '@media only screen and  (max-device-width: 1366px) and (orientation: landscape)': {
      minWidth: '14rem',
    },
    '@media only screen and  (max-device-width: 1194px) and (orientation: landscape)': {
      minWidth: '13rem',
    },
    '@media only screen (max-device-width: 1112px) and (orientation: landscape)': {
      minWidth: '10rem',
    },
  },
  invisibleText: {
    opacity: 0.4,
  },
  nameWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  name: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    fontSize: '1.2rem',
  },

  invisible: {
    margin: '0 1rem',
    height: '1.5rem',
    backgroundColor: '#000000',
    color: '#ffffff',
    fontSize: '0.7rem',
  },
}));

export const SoldoutProductInfoText: FC<Props> = ({ product }) => {
  const classes = useStyles();

  return (
    <div className={`${classes.productInfoText} ${product.isHidden ? classes.invisibleText : ''}`}>
      <div className={classes.nameWrapper}>
        <Typography className={classes.name}>{product.name}</Typography>
        {product.isHidden && <Chip label="非表示" className={classes.invisible} />}
      </div>
    </div>
  );
};

import { makeStyles } from '@material-ui/core';
import React, { FC, useContext } from 'react';
import { useMountEffect } from '../../../hooks/useMountEffect';
import { AuthContext, authOperations } from '../../../modules/general/auth';
import { UIContext } from '../../../modules/general/ui';
import { AdminMenuContext, adminMenuOperations } from '../../../modules/settings';
import { UiMessage } from '../../general/parts/uiMessage';
import { IconSideMenu } from '../units/iconSideMenu';
import { SoldoutMenuList } from '../units/soldoutProduct/soldoutMenuList';
import { SoldoutProductList } from '../units/soldoutProduct/soldoutProductList';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    backgroundColor: '#F8F8F8',
  },
  iconSideMenu: {
    maxWidth: theme.sideMenu?.width,
  },
  soldoutSideMenu: {
    minWidth: theme.sideMenu?.width,
    '@media (max-width: 1024px)': {
      minWidth: '13rem',
    },
  },
  content: {},
  testWrapper: {
    width: '100%',
  },
}));

export const SoldoutLayout: FC = () => {
  const classes = useStyles();

  const uiStore = useContext(UIContext);
  const authStore = useContext(AuthContext);
  const adminMenuStore = useContext(AdminMenuContext);

  useMountEffect(() => {
    authOperations.checkLogin(authStore.dispatch);
    adminMenuOperations.getMenus(uiStore.dispatch, adminMenuStore.dispatch);
  });

  if (!authStore.state.user) return <></>;

  return (
    <div className={classes.wrapper}>
      <IconSideMenu className={classes.iconSideMenu} />
      <SoldoutMenuList className={classes.soldoutSideMenu} />
      <SoldoutProductList className={classes.content} />
      <UiMessage />
    </div>
  );
};

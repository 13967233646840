import { makeStyles, Typography } from '@material-ui/core';
import React, { FC, useContext } from 'react';
import { UIContext } from '../../../../modules/general/ui';
import { adminMenuActions, AdminMenuContext, adminMenuOperations } from '../../../../modules/settings';
import { ClassName } from '../../../general/props/classname';
import { SoldoutCategoryList } from '../../parts/soldoutProduct/soldoutCategoryList';

type Props = ClassName;

const useStyles = makeStyles((theme) => ({
  wrapper: {
    height: '100%',
    backgroundColor: theme.sideMenu?.background,
    borderRight: '1px solid #cbcbcb',
    userSelect: 'none',
    overflowY: 'auto',
  },
  titleWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'sticky',
    top: 0,
    color: '#ffffff',
    backgroundColor: '#9dd2ac',
  },
  title: {
    textAlign: 'left',
    padding: '0.6rem',
  },
}));

export const SoldoutMenuList: FC<Props> = ({ className }) => {
  const classes = useStyles();

  const adminMenuStore = useContext(AdminMenuContext);

  const dispatchCategoryClicked = (categoryId: number) => () => {
    adminMenuStore.dispatch(adminMenuActions.categoryClicked(categoryId));
  };

  return (
    <div className={`${classes.wrapper} ${className}`}>
      {adminMenuStore.state.menus.map((menu) => (
        <div key={`menu-${menu.id}`}>
          <div className={classes.titleWrapper}>
            <Typography className={classes.title}>{menu.name}</Typography>
          </div>
          <SoldoutCategoryList categories={menu.categories} onClickFactory={dispatchCategoryClicked} />
        </div>
      ))}
    </div>
  );
};

import { makeStyles } from '@material-ui/core';
import { DTOProduct } from 'models';
import React, { FC, useMemo } from 'react';
import { SoldoutProductCheckbox } from '../../parts/soldoutProduct/soldoutProductCheckbox';
import { SoldoutProductInfoText } from './soldoutProductInfoText';

type Props = {
  product: DTOProduct;
  onClick: (product: DTOProduct) => void;
};

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    width: 'calc((89% + 18px) * 0.9)',
    marginLeft: '10%',
    borderBottom: 'solid 1px #cbcbcb',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#ededed',
    },
    userSelect: 'none',
  },
  imgAndInfo: {
    display: 'flex',
    position: 'relative',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '75%',
    margin: '1rem 0',
  },
  checkbox: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

export const SoldoutProductListItem: FC<Props> = ({ product, onClick }) => {
  const classes = useStyles();

  const soldoutEndDate = useMemo(() => {
    if (!product.soldoutEndDate) return undefined;
    const splitReceptionDate = product.soldoutEndDate.split(/[/ :]/);

    return new Date(
      Number(splitReceptionDate[0]),
      Number(splitReceptionDate[1]) - 1,
      Number(splitReceptionDate[2]),
      Number(splitReceptionDate[3]),
      Number(splitReceptionDate[4])
    );
  }, [product.soldoutEndDate]);

  const handleClick = () => {
    onClick(product);
  };

  return (
    <div className={classes.wrapper} onClick={handleClick} aria-hidden>
      <div className={classes.imgAndInfo}>
        <SoldoutProductInfoText product={product} />
      </div>
      <div className={classes.checkbox}>
        {/* カラムが日付形式であればチェック状態にする */}
        <SoldoutProductCheckbox checked={!!soldoutEndDate} />
      </div>
    </div>
  );
};

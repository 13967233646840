import { DTOTakeoutReceptionTimezone } from 'models';
import { fetchman } from '../../../utils/fetchman';
import { uiActions } from '../../general/ui';
import { TypeOfActions as TypeOfUIActions } from '../../general/ui/actions';
import { actions, TypeOfActions } from './actions';

/**
 * GET /api/takeout/shops/:id
 */
export const getShop = async (
  uiDispatch: React.Dispatch<TypeOfUIActions>,
  dispatch: React.Dispatch<TypeOfActions>,
  props: { id: number }
) => {
  try {
    uiDispatch(uiActions.fetchProgress());

    const data = await fetchman(`/api/takeout/shops/${props.id}`, { method: 'GET' });

    dispatch(actions.getSucceed(data.result));
  } catch (error) {
    uiDispatch(uiActions.fetchFailed('店舗情報の取得に失敗しました'));
  }
};

/**
 * PUT /api/takeout/settings/:shopId
 */
export const updatePreparationTime = async (
  uiDispatch: React.Dispatch<TypeOfUIActions>,
  dispatch: React.Dispatch<TypeOfActions>,
  props: { shopId: number; preparationTime: number; preparationTimeLinkage: (changedPreparationTime: number) => void }
) => {
  try {
    uiDispatch(uiActions.fetchProgress());

    await fetchman(`/api/takeout/settings/${props.shopId}`, {
      method: 'PUT',
      body: { preparationTime: props.preparationTime },
    });

    dispatch(actions.updatePreparationTimeSucceed(props.preparationTime));
    props.preparationTimeLinkage(props.preparationTime);

    uiDispatch(uiActions.fetchSucceed('商品準備時間を変更しました'));
  } catch (error) {
    uiDispatch(uiActions.fetchFailed('商品準備時間の更新に失敗しました'));
  }
};

/**
 * PUT /api/takeout/reception-timezones/:shopId
 */
export const updateReceptionTimezones = async (
  uiDispatch: React.Dispatch<TypeOfUIActions>,
  dispatch: React.Dispatch<TypeOfActions>,
  props: { shopId: number; receptionTimezones: DTOTakeoutReceptionTimezone[] }
) => {
  try {
    uiDispatch(uiActions.fetchProgress());

    await fetchman(`/api/takeout/reception-timezones/${props.shopId}/bulk-update`, {
      method: 'PUT',
      body: props.receptionTimezones,
    });

    dispatch(actions.updateReceptionTimezonesSucceed(props.receptionTimezones));

    uiDispatch(uiActions.fetchSucceed('商品受渡時間帯を変更しました'));
  } catch (error) {
    uiDispatch(uiActions.fetchFailed('商品受渡時間帯の更新に失敗しました'));
  }
};

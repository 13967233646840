import { makeStyles, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { FunctionsMenuButton } from '../../../general/parts/functionsMenuButton';
import { ClassName } from '../../../general/props/classname';
import { AdminBorder } from '../../parts/adminBorder';

type Props = ClassName;

const useStyles = makeStyles((theme) => ({
  wrapper: {
    width: '90%',
    display: 'flex',
    justifyContent: 'center',
  },
  categoryNameWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    fontSize: '2.5rem',
  },
  unselectMessage: {
    color: theme.palette.text.primary,
    '@media (max-width: 1024px)': {
      fontSize: '0.8rem',
    },
    textAlign: 'center',
    marginTop: '-3rem',
  },
  displayTimeIntervalWrapper: {
    width: '20%',
    height: '100%',
  },
  toggleMenuWrapper: {
    margin: '2rem 2rem 0 auto',
  },
  title: {
    display: 'flex',
    fontSize: '2.5rem',
    margin: '1%',
    marginRight: '15%',
  },
  subtitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-left',
    width: '85%',
    margin: '1rem auto 0',
  },
  border: {
    width: '85%',
    margin: '0 auto',
  },
}));

export const SoldoutProductListHeader: FC<Props> = ({ className }) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.categoryNameWrapper}>
        <FunctionsMenuButton className={classes.toggleMenuWrapper} />
      </div>
      <div>
        <Typography variant="h6" className={classes.subtitle}>
          商品名
        </Typography>
        <AdminBorder className={classes.border} />
      </div>
    </>
  );
};
